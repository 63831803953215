import { Button, Card, ViewContext } from "components/lib";
import { MainContext } from "contexts";
import { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Style from "./SaasFiles/frame3.module.scss";

export function Frame3(props) {
  const { quote } = useContext(MainContext);
  const viewContext = useContext(ViewContext);
  const navigate = useNavigate();

  const properties = {
    "Market Value": quote ? quote.policy.marketValue : 0,
    "Dwelling Limit": quote ? quote.policy.dwellingLimit : 0,
    "Other Structures": quote ? quote.policy.otherStructures : 0,
    "Personal Property": quote ? quote.policy.personalProperty : 0,
    "Loss of Use": quote ? quote.policy.lossOfUse : 0,
    "Personal Liability": quote ? quote.policy.personalLiability : 0,
    "Medical Payments": quote ? quote.policy.medicalPayments : 0,
    "Estimated Annual Premium": quote ? quote.policy.estimatedAnnualPremium : 0,
    "Year Of Built": quote ? quote.policy.yearOfBuild : 0,
    "Square Footage": quote ? quote.policy.squareFootage : 0,
  };
  console.log(properties)

  const showPopUpNumber = () => {
    viewContext.modal.show({
      text: "Enter your number to have full policy proposal texted to your phone",
      form: {
        phone: {
          type: "phone",
          required: true,
          placeholder: "Enter your phone number",
        },
        quoteId: {
          type: "hidden",
          required: false,
          hidden: true,
          value: quote.id,
        },
      },
      method: "POST",
      buttonText: "Text me full quotes and policy details",
      url: "api/quote/message",
    });
  };

  const showPopUpEmail = () => {
    viewContext.modal.show({
      text: "Enter your email to have full policy proposal sent to your inbox",
      form: {
        email: {
          type: "email",
          required: true,
          placeholder: "Enter your email",
        },
        quoteId: {
          type: "hidden",
          required: false,
          hidden: true,
          value: quote.id,
        },
      },
      method: "POST",
      buttonText: "Email me full quotes and policy details",
      url: "api/quote/message",
    });
  };

  const formatCurrency = (price) => {
    const numericPrice = Number(price);
    const formattedPrice = numericPrice.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    const wholeNumber = formattedPrice.split(".")[0];
    return wholeNumber.slice(1);
  };
  const formatCurrencyDots = (price) => {
    const numericPrice = Number(price);
    return numericPrice
      .toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
      .slice(1);
  };

  return (
    <Fragment>
      <div className={Style.container}>
        <Card className={Style.card}>
          <div className={Style.headerContainer}>
            <img
              src={`${process.env.PUBLIC_URL}/images/mappin.svg`}
              alt="mappin"
            />
            <div className={Style.headerMargin}>
              <h1 className={Style.headerText}>Home Insurance Quote For</h1>
              <span className={Style.headerAddress}>{quote.address}</span>
              <span
                className={Style.headerNavigation}
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
              >
                <i>Change address</i>
              </span>
            </div>
          </div>

          {/* PROPERTY DETAILS */}

          <div className={Style.mainContainer}>
            <div className={Style.propertyContainer}>
              <div style={{ height: "35%" }}>
                <h1 className={Style.propertyDetails}>Property Details</h1>
                <div className="flex">
                  <img
                    className="pb-1 pl-6 pr-2"
                    src={`${process.env.PUBLIC_URL}/images/grid.svg`}
                    alt="grid"
                  />
                  <span className="font-bold text-slate-500">
                    Square Footage:
                  </span>
                  <span className="pl-4 text-slate-400">
                    {properties["Square Footage"].toLocaleString()} sq. ft
                  </span>
                </div>
                <div className="flex">
                  <img
                    className="pb-1 pl-6 pr-2"
                    src={`${process.env.PUBLIC_URL}/images/calendar.svg`}
                    alt="calendar"
                  />
                  <span className="font-bold text-slate-500">Year Built:</span>
                  <span className="pl-4 text-slate-400">
                    {properties["Year Of Built"]}
                  </span>
                </div>
              </div>
              <div style={{ height: "50%" }}>
                <img
                  className=""
                  src={
                    quote.image
                      ? quote.image
                      : `${process.env.PUBLIC_URL}/images/map.svg`
                  }
                  alt="map"
                />
              </div>
            </div>

            <div className="flex-1 text-center">
              <h1 className={Style.quoteHeader}>Your Estimated Annual Premium</h1>
              {/* <p className="text-xl pb-4 font-semibold px-6">{selectedAddress}</p> */}
              <p className={Style.priceContainer}>
                <span className={Style.quoteDollar}>$</span>
                <span className={Style.quotePrice}>
                  {formatCurrency(properties["Estimated Annual Premium"])}/yr
                </span>
              </p>
              <p className={Style.quoteText}>
                  <i>
                    <span >Your estimate includes 1% Deductibles and is </span><br />
                    <span>100% Replacement Cost. Graymark can customize any coverages or </span>
                    <span>coverage limits to meet your needs.</span>
                  </i>
              </p>

              <div className="flex justify-center pb-2">
                <Button
                  className={Style.textButton}
                  color="green"
                  text="Text Me Full Quotes and Policy Details"
                  action={showPopUpNumber}
                />
              </div>

              <div className={Style.buttonContainer}>
                <button
                  onClick={showPopUpEmail}
                  type="button"
                  className={Style.emailButton}
                >
                  <img
                    style={{ paddingRight: "4px" }}
                    src={`${process.env.PUBLIC_URL}/images/mail.svg`}
                    alt="mail"
                  />
                  <span> Email my policy Details </span>
                </button>
                <button className={Style.callButton}>
                  <img
                    style={{ paddingRight: "4px" }}
                    src={`${process.env.PUBLIC_URL}/images/phone.svg`}
                  />
                  <a href="tel:(817) 900-7461"> Call (817) 900-7461 </a>
                </button>
              </div>

              <div className="flex justify-center pb-4 text-black"></div>
              <div className="px-6">
                <p className={Style.policyDetails}>Your Coverage Limits</p>
                <ul className={Style.list}>
                  {Object.keys(properties)
                    .filter((key) => {
                      return (
                        key !== "Market Value" &&
                        key !== "Estimated Annual Premium" &&
                        key !== "Year Of Built" &&
                        key !== "Square Footage"
                      );
                    })
                    .map((key) => {
                      return (
                        <Fragment key={key}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span className="text-left text-center">{key}</span>
                            <div
                              style={{
                                flexGrow: "1",
                                borderBottom: "2px dotted lightgrey",
                              }}
                            ></div>
                            <span
                              className="text-right"
                              style={{ float: "right" }}
                            >
                              ${formatCurrencyDots(properties[key])}
                            </span>
                            <br />
                          </div>
                        </Fragment>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
