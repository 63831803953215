import { Document, Page, Text, View, Image, Svg } from '@react-pdf/renderer';
import Justin from './images/justin.png';
import logo from './images/graymark-white.png';
import house2 from './images/weed.png';

export function MyDocument({pdfData, selectedAddress, }) {
  const dwellingLimit = pdfData.dwellingLimit || 0;
  const otherStructures = pdfData.otherStructures || 0;
  const personalProperty = pdfData.personalProperty || 0;
  const lossOfUse = pdfData.lossOfUse || 0;
  const personalLiability = pdfData.personalLiability || 0;
  const medicalPayments = pdfData.medicalPayments || 0;
  const estimatedAnnualPremium = pdfData.estimatedAnnualPremium || 0;
  const name = pdfData.owner || 0;
// {gvhjbdns}
  const [address, city, state] = selectedAddress.split(',');
  const formatCurrency = (price) =>{
    const numericPrice = Number(price);
    return numericPrice.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    }).slice(1);
  }
  return (
    <Document title='MY DOCUMENT'>
      <Page size="A4">
        <View 
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <View
            style={{
              width: "90%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#404040",
              padding: "8px",
              color: '#FFFFFF',
              margin:'10px',
            }}
          >
            <Image src={logo} alt='logo' style={{width:'30%'}} />
            
            <View
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                fontFamily: 'Helvetica-Bold'
              }}
            >
              <View
                style={{
                  fontSize: '9px',
                  textAlign: 'right',
                  color: "white",
                }}
              >
                <Text style={{alignSelf: 'flex-end'}}>RIGHT COVERAGE, LOWEST COST.</Text>
                <Text style={{ alignSelf: 'flex-end'}}>CALL OR EMAIL TODAY!!</Text>
              </View>
              <Text
                style={{
                  fontSize: '18px',
                  textAlign: 'rigth',
                  alignSelf: "flex-end",
                  color: "white",
                  fontFamily: 'Helvetica-Bold'
                }}
              >
                (817) 900-8740
              </Text>
            </View>
          </View>
          <View
            style={{
              width: "90%",
              marginTop: "12px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <View
                style={{
                  width: "70%",
                  fontSize: '10px',
                  marginTop: "12px",
                  paddingLeft:'10px',
                }}>
                  <View
                    style={{
                      fontFamily: 'Helvetica-Bold',
                    }}>
                    <Text style={{fontSize:'12px'}}>{name}</Text>
                    <Text style={{ color:'#64748b',marginTop: "16px", fontSize:'14px', borderBottom:'1px'}}>Homeowners Insurance Quote</Text> 
                  </View>
                  <View
                    style={{
                      color: "black",
                      paddingTop: "9px",
                      paddingHorizontal: "9px",
                      paddingBottom: "3px",
                      fontFamily: 'Helvetica-Bold',
                      display: 'flex',
                      flexDirection: 'row',
                    }}>
                      <Text style={{flexGrow:'1'}}>Prepared For:</Text>
                      <Text style={{textAlign:'right'}}>Prepared By:</Text>
                  </View>
                  <View
                    style={{
                      paddingHorizontal: "9px",
                      paddingVertical: "2px",
                      display: "flex",
                      flexDirection: 'row',
                    }}
                  >
                    <Text style={{flexGrow:'1'}}>{name}</Text>
                    <Text style={{textAlign:'right'}}>Justin Feaster</Text>
                  </View>
                  <View
                    style={{
                      paddingHorizontal: "9px",
                      paddingVertical: "2px",
                      display: "flex",
                      flexDirection: 'row',
                    }}
                  >
                    <Text style={{flexGrow:'1'}}>{address}</Text>
                    <Text style={{textAlign:'right'}}>(817) 900-8740</Text>
                  </View>
                  <View
                    style={{
                      paddingHorizontal: "9px",
                      paddingVertical: "2px",
                      display: "flex",
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text style={{flexGrow:'1'}}>{city},{state}</Text>
                    <Text style={{textAlign:'right'}}>justin@graymark.com</Text>
                  </View>
                </View>
                {/* <Svg>
                  {/* <Image src={house} alt='house' /> */}
                {/* </Svg> */} 
                <Image src={house2} alt='jds' />
                
            </View>
            <View
              style={{
                width: "90%",
                fontSize: '20px',
                marginTop: "16px",
                display: 'flex',
                flexDirection: 'row',
              }}>
                <View 
                  style={{
                    width:'70%',
                    color: "white",
                    paddingTop: "9px",
                    paddingHorizontal: "9px",
                    paddingBottom: "9px",
                    fontFamily: 'Helvetica-Bold',
                    backgroundColor: '#404040',
                  }}>
                <Text style={{}}> Your Estimated Annual Premium : </Text>
              </View>
              <View
                style={{
                  width:'30%',
                  color:'white',
                  paddingTop: "9px",
                  paddingHorizontal: "9px",
                  paddingBottom: "5px",
                  fontFamily: 'Helvetica-Bold',
                  backgroundColor:'#3b82f6',

                }}>
                <Text style={{ paddingLeft:'40px', alignItems:'center'}}>${formatCurrency(estimatedAnnualPremium)}</Text>
              </View>

            </View>
          {/* DEDUCTIBLESSSS */}
          <View
            style={{
              width: "90%",
              fontSize: '11px',
              marginTop: "16px",
            }}
          >
            <View
              style={{
                backgroundColor: "#d6d3d1",
                color: "black",
                paddingTop: "9px",
                paddingHorizontal: "9px",
                paddingBottom: "5px",
                fontFamily: 'Helvetica-Bold',
                borderBottom: '1px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Text style={{width: "30%"}}>Deductibles</Text>
              <Text style={{width: "70%", textAlign: "right"}}>Amount</Text>
            </View>
            <View
              style={{
                paddingHorizontal: "9px",
                paddingVertical: "4px",
                display: "flex",
                flexDirection: 'row',
              }}
            >
              <Text style={{width: "30%",}}>All Peril</Text>
              <Text style={{width: "70%", textAlign: "right"}}>1% of Dwelling Limit</Text>
            </View>
            <View
              style={{
                paddingHorizontal: "9px",
                paddingVertical: "4px",
                display: "flex",
                flexDirection: 'row',
                alignItems: "center"
              }}
            >
              <Text style={{width: "30%",}}>Wind / Hail</Text>
              <Text style={{width: "70%", textAlign: "right"}}>1% of Dwelling Limit</Text>
            </View>
          </View>
{/* COVERAGES */}
          <View
            style={{
              width: "90%",
              fontSize: '11px',
              marginTop: "16px",
            }}
          >
            <View
              style={{
                backgroundColor: "#d6d3d1",
                color: "black",
                paddingTop: "9px",
                paddingHorizontal: "9px",
                paddingBottom: "5px",
                fontFamily: 'Helvetica-Bold',
                borderBottom: '1px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Text style={{width: "30%"}}>Coverages</Text>
              <Text style={{width: "70%", textAlign: "right"}}>Limits of Liability</Text>
            </View>
            <View
              style={{
                paddingHorizontal: "9px",
                paddingVertical: "4px",
                display: "flex",
                flexDirection: 'row',
              }}
            >
              <Text style={{width: "50%",}}>Coverage A - Dwelling (100% Replacement Cost)</Text>
              <Text style={{width: "50%", textAlign: "right"}}>${formatCurrency(dwellingLimit)}</Text>
            </View>
            <View
              style={{
                paddingHorizontal: "9px",
                paddingVertical: "4px",
                display: "flex",
                flexDirection: 'row',
                alignItems: "center"
              }}
            >
              <Text style={{width: "30%",}}>Coverage B - Other Structures</Text>
              <Text style={{width: "70%", textAlign: "right"}}>${formatCurrency(otherStructures)}</Text>
            </View>
            <View
              style={{
                paddingHorizontal: "9px",
                paddingVertical: "4px",
                display: "flex",
                flexDirection: 'row',
                alignItems: "center"
              }}
            >
              <Text style={{width: "30%",}}>Coverage C - Personal Property</Text>
              <Text style={{width: "70%", textAlign: "right"}}>${formatCurrency(personalProperty)}</Text>
            </View>
            <View
              style={{
                paddingHorizontal: "9px",
                paddingVertical: "4px",
                display: "flex",
                flexDirection: 'row',
                alignItems: "center"
              }}
            >
              <Text style={{width: "30%",}}>Coverage D - Loss of Use</Text>
              <Text style={{width: "70%", textAlign: "right"}}>${formatCurrency(lossOfUse)}</Text>
            </View>
            <View
              style={{
                paddingHorizontal: "9px",
                paddingVertical: "4px",
                display: "flex",
                flexDirection: 'row',
                alignItems: "center"
              }}
            >
              <Text style={{width: "30%",}}>Coverage E - Personal Liability</Text>
              <Text style={{width: "70%", textAlign: "right"}}>${formatCurrency(personalLiability)}</Text>
            </View>
            <View
              style={{
                paddingHorizontal: "9px",
                paddingVertical: "4px",
                display: "flex",
                flexDirection: 'row',
                alignItems: "center"
              }}
            >
              <Text style={{width: "50%",}}>Coverage F - Medical Payments to Others</Text>
              <Text style={{width: "50%", textAlign: "right"}}>${formatCurrency(medicalPayments)}</Text>
            </View>
          </View>
          {/* ENDORSEMENTS */}
          <View
            style={{
              width: "90%",
              fontSize: '11px',
              marginTop: "16px",
            }}
          >
            <View
              style={{
                backgroundColor: "#d6d3d1",
                color: "black",
                paddingTop: "9px",
                paddingHorizontal: "9px",
                paddingBottom: "5px",
                fontFamily: 'Helvetica-Bold',
                borderBottom: '1px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Text>Endorsements</Text>
            </View>
            <View
              style={{
                paddingHorizontal: "9px",
                paddingVertical: "4px",
                display: "flex",
                flexDirection: 'row',
              }}
            >
              <Text>100% Replacement Cost, Foundation Coverage, Accidental Water Damage, Glass Coverage</Text>
            </View>
          </View>
          {/* DISCOUNTS APPLIED */}
          <View
            style={{
              width: "90%",
              fontSize: '11px',
              marginTop: "16px",
            }}
          >
            <View
              style={{
                backgroundColor: "#d6d3d1",
                color: "black",
                paddingTop: "9px",
                paddingHorizontal: "9px",
                paddingBottom: "5px",
                fontFamily: 'Helvetica-Bold',
                borderBottom: '1px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Text>Discounts Applied</Text>
            </View>
            <View
              style={{
                paddingHorizontal: "9px",
                paddingVertical: "4px",
                display: "flex",
                flexDirection: 'row',
              }}
            >
              <Text>Early Signing, Multiple Policy, Protective Device, Responsible Payment, Welcome, Claim Free</Text>
            </View>
          </View>
            <View
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#d6d3d1",
              width: "90%",
              marginTop: "16px"
            }}
          >
              <Image src={Justin} alt="justin" style={{width:'17%'}}/>
            <View 
              style={{
                width: "83%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "8px",
                paddingBottom: "10px",
                paddingHorizontal: "4px",
              }}
            >
              <View style={{fontFamily: 'Helvetica-Oblique', fontSize: "11px", color: '#262626',}}>
                <Text>“Hey there! This is Justin Feaster at Graymark Insurance - Texas' Most Trusted Insurance</Text>
                <Text> Broker. I‘d love the opportunity to serve you and your family. Please contact me today with</Text>
                <Text style={{paddingLeft:"150px"}}> any questions or to get started.”</Text>
              </View>
              <Text style={{fontFamily: "Helvetica-Bold"}}>(817) 900-8740  |  justin@mygraymark.com</Text>
            </View>
          </View>
          <View
            style={{
              width:"90%",
              display: "flex",
              flexDirection: 'column',
              alignItems: 'left',
              marginTop: "12px",
              color: "rgb(119,114,125)",
              fontSize: "6.8px",
              textAlign:'justify',
              marginBottom: "3px",
              
            }}
          >
            <Text style={{fontFamily: 'Helvetica-Oblique' , }}>The total estimated annual premium is based on information we obtained or assumptions we made. For example, we make assumptions on coverage amounts, deductible amounts,</Text>
            <Text style={{fontFamily: 'Helvetica-Oblique', }}>and type of home construction based on property location. Your actual premium may be higher or lower based on several factors including coverage limits and deductibles you</Text>
            <Text style={{fontFamily: 'Helvetica-Oblique'}}>choose, any applicable discounts, additional underwriting and rating criteria, and the date coverages are purchased, or date coverages become effective. Coverages and discounts</Text>
            <Text style={{fontFamily: 'Helvetica-Oblique'}}>are subject to policy terms, conditions, qualifications, and availability. This quote is not a contract or binder of insurance and to apply for insurance you must complete the application</Text>
            <Text style={{fontFamily: 'Helvetica-Oblique', marginBottom: "6px",}}>process. For more detail regarding the information used to prepare this estimate, please contact us at the phone number listed above</Text>
         </View>
        </View>
      </Page>

    </Document>
    
  );
}