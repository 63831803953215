import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { MyDocument } from "Document/pdf";
import { Card, Loader, ViewContext } from "components/lib";
import { MainContext } from "contexts";
import { getPolicyDetails, getStreetView, sendMessage, sendPdfFile } from "lib";
import { Fragment, useContext, useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input/input";
import { useNavigate } from "react-router-dom";
import Style from "./SaasFiles/insurance.module.scss";

export function Insurance(props) {
  const { quote, setQuote } = useContext(MainContext);
  const viewContext = useContext(ViewContext);

  const [loading, setLoading] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const [address, city, state] = quote.address.split(",");

    if (address && city && state) {
      const body = {
        address: address.trim(),
        city: city.trim(),
        state: state.trim(),
      };

      getPolicyDetails(body).then((response) => {
        getStreetView(quote.address).then(async (image) => {
          setQuote({
            id: response.data.id,
            policy: response.data.data,
            image: image.replaceAll(" ", "%20").replaceAll(",", ""),
            ...quote,
          });

          const pdfBlob = await pdf(
            <MyDocument
              pdfData={response.data.data}
              selectedAddress={quote.address}
            />
          ).toBlob();

          await sendPdfFile({ file: pdfBlob }, response.data.id);
          setLoading(false);
        });
      });
    }
  }, [quote.address]);
  console.log('quote',quote.policy);

  const onTextButton = () => {
    if (!phoneNumber) {
      viewContext.notification.show("Please Enter Phone Number", "error", true);
    } else {
      const type = "sms";
      const phone = phoneNumber;
      const message =
        "Thank you for your interest in Graymarks's Insurance. Please view the PDF below to see the Full Quote";
      const quote_id = 0;
      const body = { type, phone, message, quote_id };
      sendMessage(body);
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Loader text="Loading..." />
      ) : (
        <div className={Style.container}>
          <Card className={Style.card}>
            <div className={Style.addressContainer}>
              <img
                src={`${process.env.PUBLIC_URL}/images/mappin.svg`}
                alt="mappin"
              />
              <div className={Style.innerAddressContainer}>
                <h1 className={Style.insuranceQuote}>
                  Home Insurance Quote For
                </h1>
                <span className={Style.insuranceAddress}>{quote.address}</span>
                <span
                  className={Style.changeAddress}
                  onClick={() => navigate("/")}
                >
                  <i>Change address</i>
                </span>
              </div>
            </div>

            <div style={{display: "none"  }}>
              <PDFDownloadLink
                document={
                  <MyDocument
                    pdfData={quote.policy}
                    selectedAddress={quote.address}
                  />
                }
                fileName="Graymark's Insurance Policy"
              >
                {({ blob, url, loading, error }) => {
                  return loading ? "Loading document..." : "Download now!";
                }}
              </PDFDownloadLink>
            </div>

            <div className="text-center">
              <div className={Style.insuranceHeader}>
                <h1>Want to See More Than</h1>
                <h1>Just the Price?</h1>
              </div>
              <p className={Style.numberText}>
                Enter your phone number and/or email to recieve the full details of your estimate.
              </p>
              <PhoneInput
                className={Style.phoneInput}
                value={phoneNumber}
                onChange={setPhoneNumber}
                placeholder="Enter Mobile Number"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/images/phone.svg)`,
                  backgroundPosition: "1rem center",
                }}
              />

              <div className={Style.buttonContainer}>
                <button className={Style.buttonText} onClick={onTextButton}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/message.svg`}
                    alt="message"
                  />
                  <span>Send me the details</span>
                </button>
                <button
                  className={Style.buttonPrice}
                  onClick={() => navigate("/price")}
                >
                  <span>No thanks, just show me the price</span>
                </button>
              </div>
              <p className={Style.text}>
                By submitting this form, I acknowledge that I permit Graymark
                Insurance to use my information provided to search for the very
                best rates on the insurance policy I have requested. I also
                grant Graymark Insurance express consent to contact me at the
                number and/or email address I have provided above with automated
                technology in relation to this inquiry via phone, e-mail, or
                text message. I understand that I am not required to consent to
                make a purchase. Message number and frequency varies. Message
                and data rates may apply. You can text STOP to opt-out.
              </p>
            </div>
          </Card>
        </div>
      )}
    </Fragment>
  );
}
