/***
*
*   LOADER
*   Infinite spinning animation for loading states
*
**********/

import Orbit from './images/orbit.svg';
import { ClassHelper } from 'components/lib';
import Style from './loader.tailwind.js';
import ReactLoading from 'react-loading';

export function Loader(props){

  const loaderStyle = ClassHelper(Style, props);

  return (
    <div className={ loaderStyle + ' ' + props.className } style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      {/* <img src={ Orbit } className={ Style.orbit } alt='Orbit Spinner'/> */}
      <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-gray-200">
        <ReactLoading type="spin" color="#000000" height={80} width={80} />
      </div>
      <p style={{marginTop:'10rem', marginLeft:'3rem'}}>{props.text}</p>
    </div>  
  );
}