import axios from "axios";

export const getPolicyDetails = async (body) => {
  return await axios({
    method: "post",
    data: body,
    url: "/api/quote",
  }).then((response) => response.data);
};

export const getAutoCompleteResults = async (body) => {
  return await axios({
    method: "post",
    data: body,
    url: "/api/quote/places",
  }).then((response) => response.data);
};

export const getStreetView = async (address) => {
  try {
    return `https://maps.googleapis.com/maps/api/streetview?location=${address}&size=602x350&key=AIzaSyDXxkjxHrqT_gzSfR4L3ilTylRU0C7CaDM`;
  } catch (error) {
    console.log("Error fetching Street View", error);
    throw error;
  }
};

export const sendPdfFile = async (body, id) => {
  let formData = new FormData();
  formData.append("file", body.file, `Graymark-Insurance-PDF.pdf`);

  await axios.post(`/api/quote/${id}/pdf`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const sendMessage = async (body) => {
  return await axios({
    method: "post",
    data: body,
    url: "api/quote/message",
  }).then((response) => console.log(response));
};
