
import { Contact } from 'views/website/contact';
import { Pricing } from 'views/website/pricing';
import { Terms } from 'views/website/terms';
import { Privacy } from 'views/website/privacy';
import { Home } from 'views/home/home';
import { Insurance } from 'views/home/insurance';
import { Frame3 } from 'views/home/frame3';

const Routes = [
  {
    path: '/',
    view: Home,
    layout: 'app',
    title: 'Graymark Insurance'
  },
  {
    path: '/insurance',
    view: Insurance,
    layout: 'app',
    permission: 'user',
    title: 'Graymark Insurance'
  },
  {
    path: '/price',
    view: Frame3,
    layout: 'app',
    title: 'Graymark Insurance'
  }
]

export default Routes;