import { Card, Grid, Search } from "components/lib";
import { MainContext } from "contexts/Main";
import { getAutoCompleteResults } from "lib";
import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Style from "./SaasFiles/home.module.scss";

export function Home(props) {
  const policy = {
    "Dwelling Limit": "$350,400",
    "Other Structures": "$35,400",
    "Personal Property": "$245,280",
    "Loss of Use": "$140,160",
    "Personal Liability": "$500,000",
    "Medical Payments": "$5,000",
  };

  const [searchValue, setSearchValue] = useState("");
  const [searchResponses, setSearchResponses] = useState([]);
  const { setQuote } = useContext(MainContext);
  const navigate = useNavigate();

  useEffect(() => {
    const body = { input: searchValue };
    getAutoCompleteResults(body).then((response) => {
      setSearchResponses(response.data.predictions);
    });
  }, [searchValue]);

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  return (
    <Fragment>
      <Grid cols="2">
        <div className={Style.searchContainer}>
          <div className={Style.instantQuote}>
            <p>Instant Home</p>
            <p>Insurance Quote</p>
          </div>
          <div>
            <Search
              placeholder={"Your Address"}
              callback={handleSearch}
              throttle={1000}
            />
          </div>
          {searchResponses.length > 0 && (
            <Card>
              {searchResponses.map((response, index) => (
                <p
                  key={index}
                  onClick={() => {
                    setQuote({ address: response.description });
                    navigate("/insurance");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {response.description}
                </p>
              ))}
            </Card>
          )}
        </div>

        <Card className={Style.instantCard} restrictWidth>
          <p className={Style.cardAddress}>123 Your Street, Fort Worth, TX 76137</p>
          <h1 className={Style.cardInstantQuote}>Your Estimated Annual Premium</h1>
          {/* <p className={Style.cardAddress}>123 Main St.City ST 77000</p> */}
          <p className="pb-4 font-bold px-6">
            <span className={Style.dollar}>$</span>
            <span className={Style.price}>1,389/yr</span>
          </p>
          <div className={Style.sampleText}><i>            
            <span>Your estimate includes 1% Deductibles and is </span> <br />
            <span>100% Replacement Cost. Graymark can customize any </span>
            <span>coverages or coverage limits to meet your needs.</span></i>
          </div>
          <div className="px-6">
            <p className={Style.policyHeader}>Your Coverage Limits</p>
            <ul className={Style.policyDetails}>
              {Object.keys(policy).map((key) => (
                <Fragment key={key}>
                  <span className="text-left text-center">{key}&nbsp;</span>
                  <span className="text-right" style={{ float: "right" }}>
                    {policy[key]}
                  </span>
                  <br />
                </Fragment>
              ))}
            </ul>
          </div>
        </Card>
      </Grid>
    </Fragment>
  );
}
