import { createContext, useState } from "react";

export const MainContext = createContext({
  quote: {},
  setQuote: () => {},
});

function Provider({ children }) {
  const [quote, setQuote] = useState({});

  return (
    <MainContext.Provider
      value={{
        quote,
        setQuote,
      }}
    >
      {children}
    </MainContext.Provider>
  );
}

export { Provider };
